import React, { useEffect } from 'react';
import '@styles/main.scss';
import { appWithTranslation } from 'next-i18next';
import { UserProviderProps } from '@context/user.context';
import { AppContext, AppProps } from 'next/app';
import { UpdateDataLayerComponent } from '@context/analytics.context';
import { HeaderDataItem } from '@interfaces/models/header';
import NextNProgress from 'nextjs-progressbar';
import extractFromContext from '@helpers/utils/extract-from-context';
import Environment from '@config/index';
import Cookies from 'universal-cookie';
import '@helpers/polyfills';
import UserService from '@services/user-service';
import { User, UTMCookies } from '@interfaces/models/user';
import { MySizes as Sizes } from '@interfaces/models/mySizes';
import { dehydrate, DehydratedState, QueryClient, useQueryClient } from '@tanstack/react-query';
import { GlobalQueryKeys, UserQueryKeys } from '@enums/react-query-keys';
import logger from '@helpers/utils/logger/client';
import { datadogRum as DD_RUM, datadogRum } from '@datadog/browser-rum-slim';
import { Env } from '@interfaces/models/environment';
import { NextComponentType, NextPageWithLayout } from 'next';
import { appWithLayout, appWithProviders } from '@helpers/app-wrappers';
import { axios } from '@config/axios';
import useUser from '@hooks/user/use-user';
import { lookupCountry, mapUser, USER_INITIAL_STATE } from '@helpers/utils/analytics';
import { getRUMSessionSampleRate, getDeviceTypeFromUserAgent } from '@helpers/utils/general';
import Head from 'next/head';
import { DefaultError } from '@pages/_error.page';
import { getSearchPreferences } from '@api/search-preferences';
import { getPartialHeader } from '@api/static';
import { Preferences } from '@interfaces/models/preferences';
import { usePreferences } from '@context/preferences.context';
import { AppCookies } from '@enums/cookies';
import { ComponentPageType } from '@interfaces/common/common';
import { DeviceType } from '@enums/deviceType';
import usePageType from '@hooks/use-page-type';
import { enablePageworkerScript } from '@helpers/botify-pageworker';
import Script from 'next/script';

const datadogConfig: Env['datadog'] = Environment?.datadog;

type ApplicationGetInitialProps = {
  dehydratedState: DehydratedState;
  preferences: Preferences;
  // TODO: To be deprecated in the future, it's currently only used to pass user-sizes to UserContext.
  userContextProps: UserProviderProps;
  error?: unknown;
  // TODO: To be deprecated, you'll be able to get individual app cookies out of extractFromContext or from document.cookie on the client
  cookies?: Record<string, string>;
  deviceType?: DeviceType;
};

type ApplicationProps = AppProps &
  ApplicationGetInitialProps & {
    Component: NextComponentType & NextPageWithLayout;
  };

const MainComponent: React.FC<ApplicationProps> = (props) => {
  const { Component, pageProps } = props;

  const { siteId, regionName, language, currency, country } = usePreferences();
  const queryClient = useQueryClient();

  queryClient.setQueryData<ComponentPageType>([GlobalQueryKeys.PAGE_TYPE], Component?.PageType ?? 'NotSpecifiedPage');
  const { pageType } = usePageType();
  const { user, logout } = useUser();
  const dataLayer = {
    ...(user ? mapUser(user) : USER_INITIAL_STATE),
    ISO_country_code: lookupCountry(user, country),
    id_site: siteId,
    env_country: regionName,
    env_language: language,
    order_currency: currency,
    env_country_detailed: country,
  };

  useEffect(() => {
    const ejectionId = axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err?.response?.status === 401) {
          logout();
        }
        throw err;
      },
    );

    return () => {
      axios.interceptors.response.eject(ejectionId);
    };
  }, []);

  useEffect(() => {
    if (!datadogConfig) {
      return;
    }
    const isErrPage = Component?.PageType === 'ErrorPage';
    DD_RUM.init({
      clientToken: datadogConfig.clientToken,
      applicationId: datadogConfig.applicationId,
      site: datadogConfig.site,
      service: datadogConfig.service,
      env: datadogConfig.env,
      traceSampleRate: isErrPage ? 0 : datadogConfig.tracingSampleRate,
      version: process.env.appVersion,
      sessionSampleRate: getRUMSessionSampleRate(datadogConfig, Component?.PageType),
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: datadogConfig.allowedTracingOrigins,
      trackResources: true,
      actionNameAttribute: 'data-vc-dd-action-name',
    });
    DD_RUM.setGlobalContextProperty('user_agent', window.navigator.userAgent);
    DD_RUM.setGlobalContextProperty('page_type', Component?.PageType);
  }, [datadogConfig]);

  useEffect(() => {
    if (Environment.disableAnalytics === true) {
      return;
    }

    const registerServiceWorker = async (): Promise<void> => {
      try {
        if ('serviceWorker' in navigator) {
          await navigator.serviceWorker.register('/vc-service-workers.js');
        }
      } catch (e) {
        logger.error(e, 'Service worker registration failed: ');
      }
    };

    window.addEventListener('load', registerServiceWorker);
    return () => {
      window.removeEventListener('load', registerServiceWorker);
    };
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  // send custom event to braze
  useEffect(() => {
    setTimeout(() => {
      if (!window.appboy) {
        return;
      }
      const cookies = new Cookies();
      window.appboy.logCustomEvent('web_custom_start_session', {
        utm_campaign: cookies[UTMCookies.UTM_CAMPAIGN],
      });
    }, 1500);
  }, []);

  return (
    <>
      {/* Page type meta tag for cypress rewrite & redirect tests */}
      {Environment.envName !== 'production' && (
        <>
          <Head>
            <meta data-page-type={pageType} />
          </Head>
        </>
      )}
      <UpdateDataLayerComponent data={dataLayer} />
      <NextNProgress
        color="#ff5a27"
        height={2}
      />
      <Component {...pageProps} />
    </>
  );
};

const Application = (props: ApplicationProps) => {
  const { Component, preferences, userContextProps, dehydratedState, error, cookies, deviceType } = props;

  const providerProps: ApplicationGetInitialProps = {
    preferences,
    userContextProps,
    dehydratedState,
    deviceType,
  };

  if (error) {
    datadogRum.addError(error, { type: 'app getinitialprops' });
    return <DefaultError />;
  }
  const dataLayer = {
    anonymous_id: cookies[AppCookies.ANONYMOUS_USER_ID_CSR],
    server_anonymous_id: cookies[AppCookies.ANONYMOUS_USER_ID_SSR],
  };

  const fontsToPreload = [
    'bumbumtype/albra-regular.woff2',
    'monotype/helvetica-now_text.woff2',
    'monotype/helvetica-now_text_medium.woff2',
    'monotype/helvetica-now_text_bold.woff2',
  ];

  return appWithProviders(
    appWithLayout(
      <>
        <UpdateDataLayerComponent data={dataLayer} />
        <Head>
          {/* These links *MUST* stay here, because otherwise Next.js inserts them too late in the DOM */}
          <link
            rel="preconnect"
            href="https://app.launchdarkly.com"
            key="launch-darkly-preconnect"
          />
          <link
            rel="preconnect"
            href={Environment.imagesBaseUrl}
            key="vc-images-preconnect"
          />
          <link
            rel="preconnect"
            href={Environment.assetsPath}
            key="vc-assets-preconnect"
          />
          {fontsToPreload.map((src: string) => (
            <link
              key={src}
              rel="preload"
              href={`${Environment.assetsPath}/fonts/${src}`}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          ))}
        </Head>
        {enablePageworkerScript(Component?.PageType ?? 'NotSpecifiedPage') && (
          <Script
            id="botify_pageworker"
            async
            strategy="afterInteractive"
            src="https://tags.pw.adn.cloud/ER6DGC/activation.js"
          />
        )}
        <MainComponent {...props} />
      </>,
      Component?.Layout,
    ),
    providerProps,
  );
};

Application.getInitialProps = async ({ ctx }: AppContext): Promise<Partial<ApplicationGetInitialProps>> => {
  const { preferences, sessionId, userId, serverAxiosRequestData } = extractFromContext(ctx);

  const queryClient = new QueryClient();

  // These requests are needed and failing to get all should throw an error
  const mandatoryRequests = [
    queryClient.fetchQuery<string>({
      queryKey: [GlobalQueryKeys.INITIAL_REQUEST_URL],
      queryFn: () => {
        const reqProtocol = ctx?.req?.protocol === 'http' ? `${ctx?.req?.protocol}s` : ctx?.req?.protocol;
        return typeof window === 'undefined'
          ? `${reqProtocol}://${ctx?.req?.hostname}${ctx?.req?.originalUrl}`
          : window.location.href;
      },
    }),
  ];

  // These requests only get triggered based on certain conditions and may fail
  const optionalRequests = [
    queryClient.fetchQuery<HeaderDataItem[]>({
      queryKey: [GlobalQueryKeys.PARTIAL_HEADER],
      queryFn: () => getPartialHeader(preferences),
    }),
    // User
    ...(sessionId && userId
      ? [
          queryClient.fetchQuery<User>({
            queryKey: [UserQueryKeys.USER],
            queryFn: () => UserService.getUser(userId, serverAxiosRequestData),
          }),
          queryClient.fetchQuery<Sizes>({
            queryKey: [UserQueryKeys.USER_SIZES],
            queryFn: () => getSearchPreferences(serverAxiosRequestData),
          }),
        ]
      : [null, null]),
  ];

  try {
    const promiseResults = await Promise.allSettled([...mandatoryRequests, ...optionalRequests]);

    const mandatoryRequestResponses = promiseResults.slice(0, mandatoryRequests.length);
    const errorBag = mandatoryRequestResponses.filter((promiseResult) => promiseResult.status === 'rejected');
    const shouldThrowAnError = errorBag.length > 0;

    if (shouldThrowAnError) {
      throw new Error(JSON.stringify({ errorBag }));
    }

    const user = queryClient.getQueryData<User | null>([UserQueryKeys.USER]);
    const userSizes = queryClient.getQueryData<Sizes | null>([UserQueryKeys.USER_SIZES]);

    // If session cookies exist, but are expired/timed out, remove them
    if (sessionId && userId && user === null) {
      ctx.res.setHeader('Set-Cookie', [
        `${AppCookies.SESSION}=deleted; Max-Age=0`,
        `${AppCookies.UID}=deleted; Max-Age=0`,
      ]);
    }
    const cookies = new Cookies(ctx?.req?.headers?.cookie);

    return {
      dehydratedState: dehydrate(queryClient),
      preferences,
      userContextProps: {
        userSizes,
      },
      deviceType: getDeviceTypeFromUserAgent(ctx?.req?.headers?.['user-agent']),
      cookies: cookies.getAll(),
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export default appWithTranslation(Application);
